import Head from 'next/head'
import axios from 'axios';
import dynamic from 'next/dynamic';
import Slider from 'react-slick';
import Image from 'next/image';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { RiCarLine, RiHeartLine, RiInstagramLine, RiShieldLine, RiTiktokLine, RiTimeLine, RiUser2Line, RiWhatsappLine } from 'react-icons/ri';
import SliderTestimonial from '@/components/SliderTestimonial';
import { useEffect } from 'react';

const Header = dynamic(() => import('@/components/belalang-ui').then((mod) => mod.Header), {
  ssr: false,
});
const Footer = dynamic(() => import('@/components/belalang-ui').then((mod) => mod.Footer), {
  ssr: false,
});


export default function Home() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {
    const newWindow = window.open('https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ADi+Belalang+Rental%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0', '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Belalang Rental Mobil: Layanan sewa mobil bersama supir handal di wilayah Jabodetabek. Keamanan dan kenyamanan Anda adalah prioritas kami." />
        <meta name="keywords" content="rental mobil, rental mobil bekasi, sewa mobil, driver handal, keamanan, kenyamanan, Jabodetabek, perjalanan aman, armada berkualitas" />
        <meta name="author" content="Belalang Rental Mobil" />
        <meta name="robots" content="index, follow" />
        <meta name="canonical" href="https://www.belalangrentalmobil.com" />
        <meta property="og:title" content="Belalang Rental Mobil - Keamanan dan Kenyamanan Perjalanan Anda" />
        <meta property="og:description" content="Belalang Rental Mobil: Layanan sewa mobil bersama supir handal di wilayah Jabodetabek. Keamanan dan kenyamanan Anda adalah prioritas kami." />
        <meta property="og:image" content="https://belalangrental.com/images/hero.webp" />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.belalangrentalmobil.com" />
        <meta property="og:site_name" content="Belalang Rental Mobil" />
        <meta name="twitter:title" content="Belalang Rental Mobil - Keamanan dan Kenyamanan Perjalanan Anda" />
        <meta name="twitter:description" content="Belalang Rental Mobil: Layanan sewa mobil bersama supir handal di wilayah Jabodetabek. Keamanan dan kenyamanan Anda adalah prioritas kami." />
        <meta name="twitter:image" content="images/hero.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@BelalangRentalMobil" />
        <meta name="twitter:creator" content="@BelalangRentalMobil" />
        <meta name="application-name" content="Belalang Rental Mobil" />
        <meta name="apple-mobile-web-app-title" content="Belalang Rental Mobil" />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="icon" href="/favicon.ico" />
        <title>Belalang Rental Mobil - Keamanan dan Kenyamanan Perjalanan Anda</title>
      </Head>

      <Header />
      <div className='px-4 pt-40 bg-gray-100 md:px-6 lg:px-64 xl:px-64'>
        <Slider {...settings}>
          <div className='flex justify-center'>
            <Image src="/images/banner/banner-home.png" className='rounded-lg' width="1110" height="731" alt="banner-sewa" />
          </div>
          <div className='flex justify-center'>
            <Image src="/images/banner/banner-sewa.png" className='rounded-lg' width="1110" height="731" alt="banner-sewa" />
          </div>
        </Slider>

        <div className="flex flex-col justify-center gap-4 p-10 mt-20 text-center bg-white rounded-lg">
          <h1 className='text-xl font-semibold'>Selamat Datang Di Website</h1>
          <h2 className='text-5xl text-pistachio-500'> Belalang Rental </h2>
          <p className='text-lg text-gray-700'>Jasa Rental Mobil Jabodetabek</p>
        </div>

        <div className="flex flex-col items-start justify-start gap-4 mt-10 bg-white md:flex-row lg:flex-row xl:flex-row rounded-xl">
          <div className='w-full lg:w-6/12 xl:w-6/12 md:w-6/12 rounded-xl'>
            <Image src="/images/banner/about-us.png" width="523" height="438" className='w-full rounded-xl' alt="about-us" />

            <div className='flex items-start justify-between gap-5 p-8'>
              <div className='flex flex-col gap-3'>
                <a href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+Booking+mobil+di belalangrental.+Terima+kasih&type=phone_number&app_absent=0" target="_blank" className='flex items-center gap-1 px-4 py-2 border rounded-lg border-pistachio-500 text-pistachio-700'><RiWhatsappLine /> Hubungi Kami</a>
                <a href="https://www.instagram.com/belalangrental/" className='flex items-center gap-1 px-4 py-2 border rounded-lg border-pistachio-500 text-pistachio-700'><RiInstagramLine /> Instagram</a>
                <a href="https://www.tiktok.com/@belalang.rental" className='flex items-center gap-1 px-4 py-2 border rounded-lg border-pistachio-500 text-pistachio-700'><RiTiktokLine /> Tiktok</a>
              </div>
              <div>
                <span className='text-3xl text-pistachio-600'>
                  400+
                </span>
                <br />Pelanggan Merasa Puas
              </div>
            </div>
          </div>
          <div className='w-full p-8 lg:w-6/12 xl:w-6/12 md:w-6/12'>
            <h1 className='mb-4 text-2xl text-pistachio-500'>Tentang Kami</h1>
            Tak perlu khawatir, perjalanan Anda kini ada di tangan yang tepat! Di Belalang Rental Mobil, kami menyediakan layanan sewa mobil bersama supir handal di wilayah Jabodetabek, yang memastikan setiap perjalanan Anda tidak hanya nyaman, tetapi juga penuh keamanan. Kami tidak menyediakan opsi lepas kunci, karena keamanan dan kenyamanan Anda adalah prioritas kami.
            <br />
            <br />
            Kenapa memilih kami? Karena armada kami tidak hanya beragam, tetapi juga dirawat dengan baik, memenuhi standar kualitas yang tinggi untuk memastikan Anda tiba di destinasi dengan selamat dan puas. Apakah Anda membutuhkan transportasi untuk bisnis, liburan, atau acara khusus, kami siap mendukung dengan pelayanan yang profesional dan ramah.
            <br />
            <br />

            Pilih Belalang Rental Mobil, teman perjalanan terpercaya Anda. Berbagai destinasi, satu solusi berkendara yang aman, nyaman, dan terjangkau. Hubungi kami sekarang juga, dan nikmati pengalaman berkendara yang tak terlupakan!
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          <div className='bg-white rounded-xl'>
            <img src="/images/armada/hyundai-ioniq-5.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Hyundai Ioniq 5</h1>

              <h3 className='text-3xl text-green-700'>Rp. 3,5 Juta</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHyundai+IONIQ+5%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHyundai+IONIQ+5%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/suzuki-xl-7.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Suzuki New XL 7</h1>

              <h3 className='text-3xl text-green-700'>Rp. 500,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-innova-reborn.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Innova Reborn</h1>

              <h3 className='text-3xl text-green-700'>Rp. 700,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Innova+Reborn%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Innova+Reborn%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/suzuki-ertiga.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Suzuki Ertiga</h1>

              <h3 className='text-3xl text-green-700'>Rp. 450,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+Ertiga%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+Ertiga%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/hyundai-stargazer.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Hyundai Stargazer</h1>

              <h3 className='text-3xl text-green-700'>Rp. 600,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHyundai+Stargazer%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHyundai+Stargazer%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/all-new-xenia.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>All New Xenia</h1>

              <h3 className='text-3xl text-green-700'>Rp. 450,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AAll+New+Xenia%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AAll+New+Xenia%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-innova-venturer.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Innova Venturer</h1>

              <h3 className='text-3xl text-green-700'>Rp. 900,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Innova+Venturer%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Innova+Venturer%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/daihatsu-terios.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Daihatsu Terios</h1>

              <h3 className='text-3xl text-green-700'>Rp. 600,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ADaihatsu+Terios%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ADaihatsu+Terios%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/mitsubishi-xpander.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Mitsubishi Xpander</h1>

              <h3 className='text-3xl text-green-700'>Rp. 600,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMitsubishi+Xpander%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMitsubishi+Xpander%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-fortuner.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Fortuner</h1>

              <h3 className='text-3xl text-green-700'>Rp. 1,500,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Fortuner%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Fortuner%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/mitsubishi-pajero.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Mitsubishi Pajero</h1>

              <h3 className='text-3xl text-green-700'>Rp. 1,500,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMitsubishi+Pajero%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMitsubishi+Pajero%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-new-avanza.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota New Avanza</h1>

              <h3 className='text-3xl text-green-700'>Rp. 500,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+New+Avanza%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+New+Avanza%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-alphard.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Alphard</h1>

              <h3 className='text-3xl text-green-700'>Rp. 2,500,000</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Alphard%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Alphard%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-hiace.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Hiace</h1>

              <h3 className='text-3xl text-green-700'>Hubungi Kami</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Hiace%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Hiace%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/innova-zenix.png" className='w-full' alt="innova-zenix" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Innova Zenix</h1>

              <h3 className='text-3xl text-green-700'>Rp. 1,000,000</h3>
              <p className='py-3 text-gray-700'>Durasi 12 Jam </p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AInnova+Zenix%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AInnova+Zenix%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-rush.png" className='w-full' alt="toyota-rush" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Rush</h1>

              <h3 className='text-3xl text-green-700'>Rp. 600,000</h3>
              <p className='py-3 text-gray-700'>Durasi 12 Jam </p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Rush%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Rush%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/long-elf.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Long Elf</h1>

              <h3 className='text-3xl text-green-700'>Hubungi Kami</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ALong+Elf%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ALong+Elf%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/medium-bus.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Medium Bus</h1>

              <h3 className='text-3xl text-green-700'>Hubungi Kami</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMedium+Bus%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMedium+Bus%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl'>
            <img src="/images/armada/toyota-hiace-commuter.png" className='w-full' alt="" />
            <div className='p-6'>
              <h1 className='text-xl font-semibold'>Toyota Hiace Commuter</h1>

              <h3 className='text-3xl text-green-700'>Hubungi Kami</h3>
              <p className='py-3 text-red-500'><b>SK</b>: Untuk harga / diskon dll hubungi kami.</p>
              <p className='py-3 text-gray-700'>Durasi 12 Jam</p>
              <div className="flex items-center gap-2">
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Hiace+Commuter%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center w-full gap-1 px-4 py-2 text-center rounded-lg bg-pistachio-500'><RiWhatsappLine /> Pesan</a>
                <a target='_blank' href="https://api.whatsapp.com/send/?phone=%2B6287826836514&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Hiace+Commuter%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-green-500 border border-green-500 rounded-lg'><RiWhatsappLine /></a>
                <a target='_blank' href="https://www.tiktok.com/@belalang.rental" className='flex items-center justify-center gap-1 p-3 text-center text-white bg-black border rounded-lg'><RiTiktokLine /></a>
                <a target='_blank' href="https://instagram.com/belalangrental" className='flex items-center justify-center gap-1 p-3 text-center bg-white border rounded-lg border-pistachio-500'><RiInstagramLine /></a>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full mt-12 bg-gray-100'>
          <div className="flex items-center justify-between bg-pistachio-100">
            <div className='p-10'>
              <h1 className='text-xl font-semibold'>Segera Hubungi Kami</h1>
              <h2 className='my-3 text-3xl font-semibold'>Jasa Sewa Mobil Jabodetabek</h2>
              <p className='my-4'>Dapatkan Harga Serta Layanan Yang Terbaik dari kami, untuk anda.</p>

              <a href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+Booking+mobil+di%20belalangrental.+Terima+kasih&type=phone_number&app_absent=0" target='_blank' className='flex items-center justify-center gap-2 px-4 py-2 text-center text-white rounded-md bg-pistachio-500 w-max'>
                <RiWhatsappLine />
                Hubungi Kami
              </a>
            </div>
            <div>
              <Image src="/images/banner/banner-contact.png" width="192" height="248" alt="" />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 mt-12 bg-white border shadow-xl md:lg:xl:grid-cols-3 group shadow-neutral-100">
          <div
            className="flex flex-col items-center p-10 text-center cursor-pointer group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50">
            <span className="p-5 text-white bg-blue-500 rounded-full shadow-lg shadow-red-200">
              <RiUser2Line className='w-10 h-10' />
            </span>
            <p className="mt-3 text-xl font-medium text-slate-700">Team Berpengalaman</p>
            <p className="mt-2 text-sm text-slate-500">Team berpengalaman demi menciptakan perjalanan yang mulus dan lancar.</p>
          </div>

          <div
            className="flex flex-col items-center p-10 text-center cursor-pointer group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50">
            <span className="p-5 text-white bg-orange-500 rounded-full shadow-lg shadow-orange-200">
              <RiCarLine className='w-10 h-10' />
            </span>
            <p className="mt-3 text-xl font-medium text-slate-700">Armada Terbaik</p>
            <p className="mt-2 text-sm text-slate-500">Armada dengan QC sebelum berangkat, dan dipersiapkan senyaman mungkin.</p>
          </div>

          <div className="flex flex-col items-center p-10 text-center cursor-pointer group md:lg:xl:border-b hover:bg-slate-50">
            <span className="p-5 text-white bg-green-500 rounded-full shadow-lg shadow-yellow-200">
              <RiWhatsappLine className='w-10 h-10' />
            </span>
            <p className="mt-3 text-xl font-medium text-slate-700">Kemudahan Pemesanan</p>
            <p className="mt-2 text-sm text-slate-500">Tersedia whatsapp dan beragam media sosial kami anda dapat memesan armada kami.</p>
          </div>


          <div className="flex flex-col items-center p-10 text-center cursor-pointer group md:lg:xl:border-r hover:bg-slate-50">
            <span className="p-5 text-white bg-red-500 rounded-full shadow-lg shadow-lime-200">
              <RiHeartLine className="w-10 h-10" />
            </span>
            <p className="mt-3 text-xl font-medium text-slate-700">Kepuasan Pelanggan</p>
            <p className="mt-2 text-sm text-slate-500">Yet another year ! Yet another jewel in our crown!</p>
          </div>

          <div className="flex flex-col items-center p-10 text-center cursor-pointer group md:lg:xl:border-r hover:bg-slate-50">
            <span className="p-5 text-white bg-teal-500 rounded-full shadow-lg shadow-teal-200">
              <RiShieldLine className="w-10 h-10" />
            </span>
            <p className="mt-3 text-xl font-medium text-slate-700">Prioritas Keselamatan</p>
            <p className="mt-2 text-sm text-slate-500">Keselamatan dan kenyaman anda adalah prioritas utama kami.</p>
          </div>

          <div className="flex flex-col items-center p-10 text-center cursor-pointer group hover:bg-slate-50">
            <span className="p-5 text-white bg-indigo-500 rounded-full shadow-lg shadow-indigo-200">
              <RiTimeLine className='w-10 h-10' />
            </span>
            <p className="mt-3 text-xl font-medium text-slate-700">
              Jam Fleksibel
            </p>
            <p className="mt-2 text-sm text-slate-500">Hubungi kami untuk menentukan waktu
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between w-full px-20 py-10 shadow-xl bg-pistachio-600 shadow-indigo-200">
          <p className="text-white "> <span className="text-4xl font-medium">Masih bingung ?</span> <br /> <span className="text-lg">Kontak kami untuk informasi lebih lanjut ! </span></p>
          <a href="https://api.whatsapp.com/send/?phone=%2B6283821803208&text=Halo+Admin%2C+Saya+ingin+Booking+mobil+di%20belalangrental.+Terima+kasih&type=phone_number&app_absent=0" target="_blank" className="flex items-center gap-1 px-5 py-3 font-medium duration-150 bg-yellow-400 shadow-xl text-slate-700 hover:bg-white"><RiWhatsappLine /> Hubungi Kami </a>
        </div>

        <div className="flex flex-col items-center justify-between mt-12 md:flex-col lg:flex-row">
          <div className='w-full md:w-full lg:w-4/12 xl:w-4/12'>
            <h1 className='text-3xl font-semibold'>Testimoni Pelanggan</h1>
            <div className='my-4 tetx-gray-700'>Apa yang pelanggan kami katakan setelah menggunakan jasa profesional kami?</div>
          </div>
          <div className='w-full lg:w-8/12 xl:w-8/12 md:w-full'>
            <SliderTestimonial />
          </div>
        </div>

        <div className="flex flex-col justify-between mt-12 bg-white rounded-lg p-7">
          <h1 className='text-3xl font-semibold'>Note:</h1>
          <br />
          <ul className='flex flex-col gap-3 ml-5 list-disc'>
            <li>
              Tarif Sewa Avanza & Innova : Harga Sudah termasuk <b>Mobil & Supir</b> , Harga Tidak Termasuk <b>Bbm, Tol, Parkir & Makan Supir, Inap Supir</b> (Jika menginap)
            </li>
            <li>
              Tarif Sewa Toyota Hiace : Harga sudah termasuk <b>Mobil, Supir & Bbm</b>, Harga Tidak Termasuk <b>Tol, Parkir & Makan Supir, Inap Supir</b> (Jika menginap)
            </li>
            <li>
              <b>Tarif Sewa di atas masih bersifat Negosiasi & Bisa negosiasi sesuai kesepakatan</b>
            </li>
            <li>
              Rincian harga di atas tidak mengikat & dapat berubah sewaktu waktu
            </li>
            <li>
              Jam kerja supir/jam sewa perhari starting pukul 05.00 s/d 23.00
            </li>
            <li>
              Kendaraan sewa yang di gunakan lewat dari durasi sewa akan di kenakan overtime 10% dari Tarif sewa kendaraan
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  )
}