import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function SliderTestimonial() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            <Slider {...settings}>
                <div className="w-full p-px rounded-3xl bg-gradient-to-b from-blue-300 to-pistachio-500 dark:from-blue-800 dark:to-purple-800 ">
                    <div className="rounded-[calc(1.5rem-1px)] p-10 bg-white dark:bg-gray-900">
                        <p className="text-gray-700 dark:text-gray-300">Pelayanan nya sangat baik, sampai tujuan dengan selamat.</p>

                        <div className="flex items-center gap-4 mt-8">
                            <div>
                                <h3 className="text-lg font-medium text-gray-700 dark:text-white">Anggi Kusuma</h3>
                                <span className="text-sm tracking-wide text-gray-600 dark:text-gray-400">Pelanggan</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full p-px rounded-3xl bg-gradient-to-b from-blue-300 to-pistachio-500 dark:from-blue-800 dark:to-purple-800 ">
                    <div className="rounded-[calc(1.5rem-1px)] p-10 bg-white dark:bg-gray-900">
                        <p className="text-gray-700 dark:text-gray-300">Mobil bersih, pelayanan baik, supir ramah.</p>

                        <div className="flex items-center gap-4 mt-8">
                            <div>
                                <h3 className="text-lg font-medium text-gray-700 dark:text-white">Budi Lesmana</h3>
                                <span className="text-sm tracking-wide text-gray-600 dark:text-gray-400">Pelanggan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default SliderTestimonial